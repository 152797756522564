import tw from "twin.macro"
import { Image } from "../../atoms/Image"
import { SimpleGalleryProps } from "./SimpleGallery.d"
import { Carousel, CarouselIndicator, CarouselItem } from "../../atoms/Carousel"
import Icon from "../../atoms/Icon"
import { useContext, useState } from "react"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { LanguageContext } from "../../../contexts/Language"
import { parseDisclaimerBlocks } from "../../../helpers"
import { toggleDisclaimersModal } from "../../../contexts/Disclaimers/actions"
import { DisclaimersContext } from "../../../contexts/Disclaimers"
import NewInventoryCarousel from "../../organisms/Inventory/Carousel"
import { css } from "@emotion/react"

const SimpleGallery: React.FC<SimpleGalleryProps> = ({
  slides,
  inventory = false,
  inventoryData,
  navigation = true,
  indicators = true,
  wrapperStyles = tw`h-[220px] md:h-[500px] lg:h-[600px]`,
  indicatorStyles = [],
  chevronColorProp = "white",
  chevronPosition = "inside",
  showImagePosition = false,
  selectedSeries,
  vehicleEligibility = true,
  location,
  imageType,
  vehiclePosition,
  selectedSeriesYear,
  hasExtraTealData = false,
  showProgressBar = false,
  showImageCount = true,
  ...remainingProps
}) => {
  const [leftChevronColor, setLeftChevronColor] = useState(chevronColorProp)
  const [rightChevronColor, setRightChevronColor] = useState(chevronColorProp)
  const { _ } = useContext(LanguageContext)
  const [state, dispatch] = useContext(DisclaimersContext)

  // Tealium
  const { trackTealEvent } = useTealiumEvent()

  /**
   * @function
   * @name handleTealiumEvent
   * @param {string} direction - The direction of the carousel click
   * @param {number} i - The index of the slide
   * @param {string} captionHeading - The caption heading of the slide
   * @summary Handles the Tealium event for the carousel
   * @returns {void}
   * @description hasExtraTealData is used to determine if the additional Tealium values are being used for the Tealium call
   **/
  const handleTealiumEvent = (
    direction: string,
    i?: number,
    captionHeading: string = "",
  ) => {
    trackTealEvent({
      tealium_event: "carousel_click",
      carousel_action: direction,
      ...(selectedSeries && { vehicle_model_truncated: selectedSeries }),
      ...(hasExtraTealData
        ? {
            gallery_image_details: `${selectedSeries}|${selectedSeriesYear}|${
              captionHeading || undefined
            }|Gallery|${i + 1}`,
          }
        : undefined),
    })
  }
  if (inventory) {
    return (
      <NewInventoryCarousel
        inventory
        inventoryData={inventoryData}
        continuous
        section={"vehicles"}
        slides={vehicleEligibility ? slides : [slides[0]]}
        css={wrapperStyles}
        chevronPosition={chevronPosition}
        location={location}
        imageType={imageType}
        vehiclePosition={vehiclePosition}
      >
        {({ slideIndex }) => {
          const Img = slides[slideIndex]?.image
          return (
            <div>
              {showProgressBar && (
                <div
                  css={[
                    tw`overflow-visible absolute pb-[1px] flex justify-center z-[61] bottom-0 w-full bg-white`,
                  ]}
                >
                  {/* Progress bar that fills by % */}
                  <div
                    css={[
                      tw`border-transparent mt-2 h-[1px] bg-gray-400 w-[60%]`,
                    ]}
                  >
                    <div
                      css={[
                        tw`h-[3px] mt-[-1px] border border-gunmetal bg-gunmetal`,
                        css`
                          width: ${(
                            (slideIndex + 1) *
                            (100 / slides.length)
                          ).toString()}%;
                        `,
                      ]}
                    />
                  </div>
                </div>
              )}
              <div
                css={[
                  tw`max-w-screen-xl w-full mx-auto relative h-full`,
                  wrapperStyles,
                ]}
              >
                <CarouselItem active={true}>
                  {showImageCount &&
                    vehicleEligibility &&
                    slides?.length > 1 && (
                      <span
                        css={[
                          tw`rounded-full absolute top-2 left-2 bg-gray-100 text-xs py-1 px-2`,
                        ]}
                      >
                        {_("Image")} {slideIndex + 1} / {slides.length}
                      </span>
                    )}
                  <Image
                    src={typeof Img === "string" && Img}
                    css={[tw`w-full h-full object-cover`]}
                  />
                  {!vehicleEligibility && (
                    <div
                      css={[
                        tw`absolute top-0 right-0 bottom-0 left-0 bg-gray-500 bg-opacity-80 text-3xl flex items-center`,
                      ]}
                    >
                      <span
                        css={[tw`text-center font-semibold px-10 flex-grow`]}
                      >
                        {_("Sorry, this vehicle is no longer available.")}
                      </span>
                    </div>
                  )}
                </CarouselItem>
              </div>
            </div>
          )
        }}
      </NewInventoryCarousel>
    )
  }
  return (
    <Carousel slides={slides}>
      {({ slides, changeSlide, activeSlide }) => (
        <div css={[navigation && tw`px-0 `]}>
          {/* slides */}
          <div
            css={[
              tw`max-w-screen-xl w-full mx-auto relative h-full`,
              wrapperStyles,
            ]}
          >
            {slides?.map(({ image, alt, captionHeading, captionBody }, i) => (
              <CarouselItem active={i === activeSlide}>
                {showImagePosition && slides.length > 1 && (
                  <span
                    css={[
                      tw`rounded-full absolute top-2 left-2 bg-gray-100 text-xs py-1 px-2`,
                    ]}
                  >
                    {_("Image")} {i + 1} / {slides.length}
                  </span>
                )}
                <Image
                  imageData={image}
                  css={[tw`w-full h-full object-cover`]}
                />
                {indicators && (
                  <div
                    css={[
                      tw`flex justify-center flex-wrap py-4`,
                      ...indicatorStyles,
                    ]}
                  >
                    {slides.map((slide, i) => (
                      <CarouselIndicator
                        active={activeSlide == i}
                        slideId={i}
                        changeSlide={changeSlide}
                        css={[tw``]}
                        analyticsId={`radio button:gallery:${i + 1}`}
                        handleTealClick={handleTealiumEvent}
                        captionHeading={slide?.captionHeading}
                      />
                    ))}
                  </div>
                )}

                {captionHeading && (
                  <div
                    css={[
                      tw`text-white font-light text-2xl text-center tracking-widest`,
                    ]}
                  >
                    {parseDisclaimerBlocks(captionHeading, selection =>
                      dispatch(toggleDisclaimersModal(selection)),
                    )}
                  </div>
                )}
                {captionBody && (
                  <div
                    css={[
                      tw`text-white px-8 font-book text-base text-center lg:(px-0)`,
                    ]}
                  >
                    {parseDisclaimerBlocks(captionBody, selection =>
                      dispatch(toggleDisclaimersModal(selection)),
                    )}
                  </div>
                )}
              </CarouselItem>
            ))}
            {navigation && (
              <button
                onClick={() => {
                  changeSlide(activeSlide - 1),
                    handleTealiumEvent(
                      "left arrow",
                      activeSlide - 1,
                      slides[activeSlide - 1]?.captionHeading,
                    )
                }}
                onMouseEnter={() => setLeftChevronColor("white")}
                onMouseLeave={() => setLeftChevronColor("gray-900")}
                css={[
                  tw`bg-white bg-opacity-25 absolute top-1/2 left-2 transform -translate-y-1/2 z-50`,
                  tw`hover:(bg-gray-800 bg-opacity-75)`,
                  tw`focus:(bg-gray-800 bg-opacity-75 outline-white) disabled:(opacity-0 invisible)`,
                ]}
                disabled={activeSlide == 0}
                analytics-id="left arrow:gallery:gallery"
              >
                <Icon.Chevron
                  css={[tw`m-2 h-10 inline lg:(m-4)`]}
                  color={leftChevronColor}
                  direction="left"
                />
              </button>
            )}
            {navigation && (
              <button
                onClick={() => {
                  changeSlide(activeSlide + 1),
                    handleTealiumEvent(
                      "right arrow",
                      activeSlide + 1,
                      slides[activeSlide + 1]?.captionHeading,
                    )
                }}
                onMouseEnter={() => setRightChevronColor("white")}
                onMouseLeave={() => setRightChevronColor("gray-900")}
                css={[
                  tw`bg-white bg-opacity-25 absolute top-1/2 right-2 transform -translate-y-1/2 z-50`,
                  tw`hover:(bg-gray-800 bg-opacity-75)`,
                  tw`focus:(bg-gray-800 bg-opacity-75 outline-white) disabled:(opacity-0 invisible)`,
                ]}
                disabled={activeSlide == slides.length - 1}
                analytics-id="right arrow:gallery:gallery"
              >
                <Icon.Chevron
                  css={[tw`m-2 h-10 inline lg:(m-4)`]}
                  color={rightChevronColor}
                  direction="right"
                />
              </button>
            )}
          </div>
        </div>
      )}
    </Carousel>
  )
}

export default SimpleGallery
